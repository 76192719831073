<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Hub to RTM Create</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label for="rider">Rider <span class="text-danger" title="Required">*</span></label>
                                        <input type="text" class="form-control" v-model="form.rider" placeholder="Rider Name"> 
                                    </div>
                                </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="date">Barcode Type or Scan</label>
                                            <input type="text" class="form-control" v-model="itemId" @keyup.enter="keepOrder" placeholder="Scan barcode or type order id and press enter"> 
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:26px">
                                            <button class="btn btn-info" type="submit" @click.prevent="submitData">Send to RTM</button>
                                            <b style="margin-left:10px; font-size:18px">Total:{{ totalParcel }}</b>
                                            <b style="margin-left:10px; font-size:18px">Scaned:{{ form.order_ids.length }}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <span v-for="(orderId, index) in form.order_ids" :key="index" class="btn btn-sm btn-warning mb-1 mr-1">
                                    {{ orderId }} <i class="ml-1 fas fa-times" @click="removeRow(orderId)"></i>
                                </span>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Order ID</th>
                                                    <th>Merchant</th>
                                                    <th>Customer</th>
                                                    <th>Address</th>
                                                    <th>Status</th>
                                                    <th>Price</th>
                                                    <th>Order Date</th>
                                                    <th>Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ item.id }}</td>
                                                    <td @click="showOrderLog(item.logs)">{{ item.merchant.business }}</td>
                                                    <td>{{ item.name }}, {{ item.mobile }}</td>
                                                    <td>{{ item.address }}</td>
                                                    <td><span :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>
                                                    <td>{{ item.price }}</td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.reason }}</td>
                                                </tr>
                                            </tbody>
                                        </table>           
                                    </div>    
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>

        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Action By</th>
                                <th>Action</th>
                                <th>Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                            </tr>
                        </table>                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import config from '@/config'
import ExportPdf from './HubToRtm'

export default {
    name:'HubToRtmCreate',
    data(){
        return {
            loader: false,
            listData: [],
            itemId: '',
            totalParcel: 0,
            riderDetails: '',
            orderLogModal:false,
            logs:[],
            form: {
                rider:'',
                order_ids: []
            }
        }
    },
    created() {
        this.loadData()
    },
    computed : {
        authUser () {
            return this.$store.state.authUser
        },
        customState () {
            return this.$store.state
        },
        riderList () {
            if (this.authUser.role_id == 7) {
                return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
            }            
            return this.$store.state.commonObj.riderList
        },
        hubList () {          
            return this.$store.state.commonObj.hubList
        }
    },
    methods:{
        async loadData() {     
            this.totalParcel = 0
            this.form.order_ids = []
            this.loader = true 
            const params = Object.assign(this.form, { hub_id: this.authUser.hub_id })
            const response = await config.getData('/return/hub-to-rtm-create', params)
            this.loader = false
            if (response.success){
                this.totalParcel = response.data.length
                this.listData = response.data
            } else {
                this.listData = []
            } 
        },
        getStatus (status) {
            const tmpStatus = this.customState.statusList.find(tmp => tmp.id == status)
            return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
        },
        keepOrder() {
            const tmpItem = this.listData.find(el => el.id === parseInt(this.itemId))
            if (typeof tmpItem !== 'undefined') {
                this.form.order_ids.push(this.itemId)
                this.listData = this.listData.filter(el => el.id !== parseInt(this.itemId))
                this.totalParcel -= 1
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, parcel not found',
                    color: '#dc3545'
                })
            }  
            this.itemId = ''
        },
        removeRow(itemId){
            this.form.order_ids = this.form.order_ids.filter(el => parseInt(el) !== parseInt(itemId))
        },
        async submitData(){     
            if (this.form.rider) {
                this.loader = true 
                const response = await config.postData('/return/hub-to-rtm-store', this.form)
                this.loader = false
                if (response.success){    
                    this.form.order_ids=[]  
                    this.loadData()     
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })                    
                } 
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Please enter rider name',
                    color: '#dc3545'
                })
            }
        },   
        showOrderLog (orderLogs) {
            this.logs = orderLogs
            this.orderLogModal = true
        },
        cancelModal () {
            this.logs = []
            this.orderLogModal = false
        },
        pdfDownload(data, hubName) {
            const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
            ExportPdf.hubToRtmPdfDetails(base64Logo, data, hubName)
        }
    }
}
</script>